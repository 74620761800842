import React, { useContext } from 'react';

import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';

import ModalContext from './ModalContext';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '2100 !important'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export const ModalBox = ({ modal }) => {
  const { 
    isOpen = false,
    modalProps = {},
    close = () => {}
  } = modal;
  const classes = useStyles();
  const {
    title,
    message,
    buttons,
    record,
    onBackdropClick
  } = modalProps
  return (
    <div className="static-modal">
      <Modal
        open={isOpen}
        BackdropComponent={Backdrop}
        onBackdropClick={e => onBackdropClick({ close })}
        className={classes.modal}
      >
        <div className={classes.paper}>
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem' }}>
            <h5 style={{ textDecoration: 'underline' }}>{title}</h5>
          </div>

          <div style={{ position: 'relative', flex: '1 1 auto', padding: '1rem' }}>{message}</div>
          <div style={{
            display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'flex-end', padding: '0.75rem'
          }}
          >
            {
              buttons?.map((button,idx) => {
                return (
                  <Button 
                  key={`${button.text.replace(/ /g, '')}`}
                    color={button.color || 'default'}
                    variant="contained"
                    onClick={(_) => button.onClick(record, { close })}
                    style={idx !== buttons.length - 1 ? {marginRight: '0.3rem'} : {}} 
                    className={button.className || ''} 
                  >
                    {button.text}
                  </Button>
                )
              })
            }
          </div>
        </div>
      </Modal>
    </div>
  )
};

export default ModalBox;
