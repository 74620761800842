import { useState } from 'react';

export const useModalState = initialState => {
  const { open, ...initialStateMinusOpen } = initialState
  const [isOpen, setIsOpen] = useState(open || false)
  const [modalProps, setModalProps] = useState(initialStateMinusOpen)

  const updateModalProps = (newModalProps) => {
    setModalProps(oldModalProps => ({ ...oldModalProps, ...newModalProps }))
  }

  const ref = {
    isOpen,
    modalProps,
    close: () => setIsOpen(false)
  }

  return [ref, setIsOpen, updateModalProps]
}

export default useModalState;
